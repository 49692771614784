import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import "./p.css"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEye,faUniversity,faPen,faDownload,faArrowLeft, faAngleLeft, faList, faSave, faPerson, faUser, faUpload, faClose, faPhotoVideo, faImage, faPlus } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from '../../../config';
import p404 from "../../../images/undraw_text_files_au1q.png"
import Tree from 'react-d3-tree';
import Treetest from '../../../Comps/Tree';
import { Confirm,CustomDialog,StaticDialog } from 'react-st-modal';
import RiskAppreciationTable from '../../../Comps/RiskAppreciationTable';
import imagem from "../../../../src/images/img.png"
import ImageMarker from '../../../Comps/ImageMarker';
import ImageView from '../../../Comps/ImageView';
import TextView from '../../../Comps/TextView';




import pa from "../../../images/p1/a.png"
import b from "../../../images/p1/p2.png"
import f1 from "../../../images/pdf/f1.png"
import f2 from "../../../images/pdf/f2.png"
import f3 from "../../../images/pdf/f3.png"
import f4 from "../../../images/pdf/f4.png"
import f5 from "../../../images/pdf/f5.png"
import f6 from "../../../images/pdf/f6.png"
import f7 from "../../../images/pdf/f7.png"
import f8 from "../../../images/pdf/f8.png"
import f10 from "../../../images/pdf/f10.png"
import f11 from "../../../images/pdf/f11.png"
import f12 from "../../../images/pdf/f11.png"
import g1 from "../../../images/pdf/g1.png"
import g2 from "../../../images/pdf/g2.png"
import g3 from "../../../images/pdf/g3.png"
import g4 from "../../../images/pdf/g4.png"
import g5 from "../../../images/pdf/g5.png"
import g6 from "../../../images/pdf/g6.png"
import g7 from "../../../images/pdf/g7.png"
import g8 from "../../../images/pdf/g8.png"
import g9 from "../../../images/pdf/g9.png"
import h1 from "../../../images/pdf/h1.png"
import h2 from "../../../images/pdf/h2.png"
import h3 from "../../../images/pdf/h3.png"
import h4 from "../../../images/pdf/h4.png"
import c1 from "../../../images/p1/c.png"
import Apc from '../../../Comps/Apc';
import Adcs from '../../../Comps/Adcs';
import Grouplist from '../../Grouplist';
class PrevCot extends Component {
  constructor(props) {
    super(props);
    this.state = {
     Loader:true,
     p404:false,
     data:[],
     normas:[],
     tecs:[],
     Opc:[],
     images:[],
     isOpen: false,
     isActive: false,
     diagram:[],
     form:[],
     grupoi:[],
     group_2:[],
     apc:[],
     gplist:[],
     ModalOpen:false,
     upl7:[],
     AtMlhs:[],
     upload5:false,
     ImagePreview:undefined,
     ImgPrev:undefined,
     slcE:false,
     itemE:undefined
     
    };
    this.handleFileChange = this.handleFileChange.bind(this);
  }


  AddImg(event){
  

    const file = event.target.files[0];
    
    if (file) {
      const isImage = file.type.startsWith('image/');
      if(isImage == false){
        event.target.value = '';
    toast.warn("Somente imagens são aceitas!")
        return false;
      }
    
    }
    
    const reader = new FileReader();
    reader.onloadend = () => {
      this.setState({
        ImgPrev: reader.result, 
      });
    };
    reader.readAsDataURL(file); 
    
    
    
    
    this.setState({upl7:event.target.files})
    
    
                            
}

componentDidMount() {
    document.title = "Pré-visualização";
    var url  = window.location.href;
    const match = url.match(/preview\/(.*)$/);
    fetch(`${apiUrl}/${window.location.pathname.split("/")[1]}/preview/${match[1]}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+Cookies.get("auth_token"),
        }
      }).then(response => {
if(response.status == 200){
response.json().then(data=>{

  this.setState({
    data,
    diagram:data.diagram,
    form:data.form,
    grupoi:data.groupi,
    group_2:data.group_2,
    apc:data.apc,
    gplist:data.GroupList,
    Loader:false,
    ImageView:data.ImageView,
    TextView:data.TextView,
    FormHrn:data.FormHrn
  })

  this.setState(prevState => ({
    images: [...prevState.images, ...data.images]
  }));

})
}
if(response.status == 404){
this.setState({Loader:false,p404:true})
}
      })
}

formatCNPJ(cnpj) {
  // Remove caracteres não numéricos
  cnpj = cnpj.replace(/\D/g, '');

  // Adiciona os pontos e barra de acordo com o padrão
  cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

  return cnpj;
}

handleToggle = () => {
 // this.setState(prevState => ({ isOpen: !prevState.isOpen }));
};
generate_token(length){
  //edit the token allowed characters
  var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
  var b = [];  
  for (var i=0; i<length; i++) {
      var j = (Math.random() * (a.length-1)).toFixed(0);
      b[i] = a[j];
  }
  return b.join("");
}


UploadGp2(event,e){
 
  const file = event.target.files[0];
  
 
  if (file) {
    const fileType = file.type;
    if (!fileType.startsWith('image/')) {
      toast.warn("Por favor, selecione uma imagem válida!")
      event.target.value = null;
    } else {
      
      const formData = new FormData();
      
      formData.append('imagem', file);
      formData.append('code',e.code);
      formData.append('type',e.type);
      
      fetch(`${apiUrl}/Image-gp2`, {
        method: 'POST',
        body: formData
       }).then(response => {
        if(response.status == 200){
          response.json().then(data=>{
            toast.success("Salvo!")
            this.setState(prevState => {
              const novoGrupo2 = [...prevState.group_2];
              novoGrupo2[e.index].itens[e.type] = data.img;
             
              return { group_2: novoGrupo2 };
            });
          })
          
        }
      })


      
     
    }
  }


}



handleFileChange(event){

  var file = event.target.files;

  const formData = new FormData();
    if (file) {
      Array.from(file).forEach((file) => {
        formData.append('images', file);
      });


      fetch(`${apiUrl}/solicitante/imagens/`+window.location.pathname.split("/")[3], {
        method: 'POST',
        body: formData
       }).then(response => {
        if(response.status == 200){
          response.json().then(data=>{
            
          


            this.setState((prevState) => {
              
              const novaLista = [data[0], ...prevState.images];
            
         
              return {
              images:novaLista
              };
            });




          })
        }
       })




    }






}
 
   
    

      



handleOpc= (e, index) => {
  const { name, value } = e.target;

var check = this.state.form.some(u=> u.e === `${index}`);
if(check){

  this.setState(prevState => ({
    form: prevState.form.map(item =>
      item.e === `${index}` ? { ...item, marker:`${value}`} : item
    )
  }));

this.setState(prevState => ({
  Opc: {
    ...prevState.Opc,
    [name]:{
      m:value,
  
    }
  }
}));

}else{



  this.setState(prevState => ({
    form: [...prevState.form,{
     e:`${index}`,
     marker:`${value}`
    }]
  }));



this.setState(prevState => ({
  Opc: {
    ...prevState.Opc,
    [name]:{
      m:value
    }
  }
}));


 
}



  
};


  render() {
    var {info} = this.state.data;
    if(this.state.Loader == true){
        return(<>
        <div className="loader-container">
        <div class="loader"></div>
        </div>
       
        </>)
    }
    if(this.state.p404 == true){
      return(<>
      <div class="p404">
      
        <img src={p404}/>
       <p>Desculpe, Esse documento não foi encontrado.</p>
       <button type="submit" class="btn btn-primary float-left ml-2" onClick={()=>{
                  this.props.navigate(`/${window.location.pathname.split("/")[1]}`)
               }}> <FontAwesomeIcon color='#fff' icon={faArrowLeft} /> Início</button>
      
     
      
      </div>
      </>)
    }

    return (
   
        <>
        
        
        <header class="bg-white shadow"><div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <button class="font-semibold text-xl text-gray-800 leading-tight" onClick={()=>{
               this.props.navigate(-1)
            }}>
        <FontAwesomeIcon icon={faAngleLeft} />  Pré-visualização</button>
          
        </div></header>
       
        <main>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    
                   

                   
                   
                   
                   
                    <div className="col-12 mt-3" style={{display:'block'}}>
                        <div className="card">
                           
                            <div class="card-header d-flex justify-content-between">
                            <h3>INTRODUÇÃO:</h3>
                            <div>
                              
<a href={`${apiUrl}/generate/docx/${info.token}`}>                          
<button type="submit" class="btn btn-primary float-right ml-2">  <FontAwesomeIcon color='#fff' icon={faDownload} />  Baixar .docx</button>
</a>

<a href={`${apiUrl}/generate/${info.token}`}>
<button type="submit" class="btn btn-primary float-right ml-2">  <FontAwesomeIcon color='#fff' icon={faDownload} />  Baixar .pdv</button>
</a>

        
</div>

</div>
<div class="card-body">
<table className="table table-bordered t-nv">
        <thead>
          <tr>
           
          </tr>
          </thead>
          <tbody>
  <tr>
    <td>EXECUTANTE:</td>
    <td>
      
    <div onClick={()=>{
      this.setState({slcE:true})
    }} style={{
      padding:10,
      border:'solid 1px #ccc',
      borderRadius:5,
      cursor:'pointer'
    }}>{info.executante == undefined ? "Selecione:" : <>{this.state.data.executante.razao_social} - {this.formatCNPJ(this.state.data.executante.cnpj)}</>}</div>
      
      
  </td>
  </tr>
  <tr>
    <td>CONTRATANTE:</td>
    <td>{this.state.data.contratante.razao_social} - {this.state.data.contratante.cnpj}</td>
  </tr>

  <tr>
    <td>Rev:</td>
    <td>  <TextView type="text"
 code="drt6+62rse+2tr" 
 data={this.state.TextView}
/></td>
  </tr>


  <tr>
    <td>Número:</td>
    <td>  <TextView type="text"
 code="32+fg2+trhstr" 
 data={this.state.TextView}
/></td>
  </tr>


  <tr>
    <td>MÁQUINA / EQUIPAMENTO:</td>
    <td>  <TextView type="text"
 code="dffhdf6h1str" 
 data={this.state.TextView}
/></td>
  </tr>
  <tr>
    <td>ART VINCULADA: Nº</td>
    <td>

  <TextView type="text"
 code="dffg656h1str" 
 data={this.state.TextView}
/>
    </td> 
  </tr>
  <tr>
    <td>ESTADO:</td>
    <td>
    <TextView type="text"
 code="dffgutdrstr" 
 data={this.state.TextView}
/>
      </td> 
  </tr>
  <tr>
    <td>data:</td>
    <td>
    <TextView type="text"
 code="dfghfdfgdstr" 
 data={this.state.TextView}
/>
      </td> 
  </tr>
  <tr>
    <td>ARQUIVO DIGITAL:</td>
    <td>
    <ImageView code="grtssgfysh" data={this.state.ImageView}/> 
   </td>
  </tr>
  <tr>
    <td>Site:</td>
    <td>
    <TextView type="text"
 code="dfgdtyud+sdr51tr" 
 data={this.state.TextView}
/>
      </td>
  </tr>
</tbody>
      </table>
      <hr/>
      <div className='smr'>
     <span>Sumário</span>
     <div className='sub'></div>
     <div className='fn'></div>
     </div>
     <ul className="topic-list">
             {[
       "1- INTRODUÇÃO 3",
       "2- NORMAS REFERENCIADAS 4",
       "3- CARACTERIZAÇÃO DA EMPRESA SOLICITANTE 5",
       "4- CARACTERIZAÇÃO DA EMPRESA EXECUTANTE 5",
       "5- RESPONSÁVEIS TÉCNICOS 6",
       "6- ESCOPO 7",
       "7- MÉTODO DE AVALIAÇÃO PARA ESTIMATIVA DE RISCO CONFORME METODOLOGIA HAZARD RATING NUMBER (HRN) 8",
       "8- DETERMINAÇÃO DA CATEGORIA 11",
       "9- DOCUMENTAÇÃO DO EQUIPAMENTO: 21",
       "10- VISÃO GERAL DA MÁQUINA 22",
       "11- DEFINIÇÃO DA CATEGORIA DE SEGURANÇA 26",
       "12- AVALIAÇÃO – APRECIAÇÃO DE RISCOS 28",
       "12.1 – Arranjo físico das instalações – Geral 28",
       "12.2 Instalações e dispositivos elétricos – Geral 28",
       "12.3 Dispositivo de partida, acionamento e parada – Geral 37",
       "12.4 Sistemas de Segurança 38",
       "12.5 Dispositivo de parada de emergência – Geral 51",
       "12.6 Manutenção, inspeção, preparação, ajuste, reparo e limpeza. 51",
       "12.7 Sinalização, manuais, procedimentos e capacitação 53",
       "13- VISÃO GERAL DE CONFORMIDADE 55",
       "14- CONSIDERAÇÕES FINAIS 56",
       "15- CORPO TÉCNICO RESPONSÁVEL 57"
     ].map((topic, index) => {
               const [leftPart, rightPart] = topic.split(/\s(\d+)$/);
               return (
                 <li key={index} className="topic-item">
                   <span className="topic-left">{leftPart}</span>
                   <span className="dots"></span>
                   <span className="topic-right">{rightPart}</span>
                 </li>
               );
             })}
           </ul>
         
<div>


</div>



</div>

                        </div>
                    </div>


<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                          <h3>1 - INTRODUÇÃO</h3>
                           
                         </div>
                         <div class="card-body">
                         <p>
  Tendo em vista a adequação de segurança para máquinas e equipamentos conforme NR-12 port.       916 de 30 de julho de 2019 o presente documento denominado “A.R”. –  Apreciação de Riscos tem como objetivo realizar a análise técnica do equipamento: 
  <strong>Nome da máquina</strong>
   segundo normas técnicas oficiais vigentes.
  </p>
                          </div>

                          </div>
</div>

<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>2 - NORMAS REFERENCIADAS</h3>
                           <Link to="/normas" class="btn btn-primary float-right ml-2">  <FontAwesomeIcon color='#fff' icon={faList} /> Normas</Link>

                         </div>
                         <div class="card-body">
                         
             <div class="form-group">
           
                <select class="form-control" value="Selecione" onChange={(event)=>{
 const selectedId = parseInt(event.target.value, 10);

 const selectedItem = this.state.data.normas.find(item => item.id === selectedId);

 const check = this.state.data.doc_norma.some(usuario => usuario.titulo === selectedItem.titulo);

if(check){
  toast.warn(`A norma: ${selectedItem.titulo} já foi inserida!`)
  return false;
}




fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/atualizar-normas/`, {
 method: 'POST',
 credentials: 'include',
 headers: {
   'Content-Type': 'application/json',
   'Authorization': 'Bearer '+Cookies.get("auth_token"),
 },
 body:JSON.stringify({ids:`${selectedId}`})
}).then(response => {
 
 if(response.status == 200){
  
 response.json().then(data=>{
        toast.success("Normas atualizadas com sucesso!")
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            doc_norma: [...prevState.data.doc_norma, selectedItem]
          }
        }));
 })
      }
})







                }} id="exampleSelect">
                   <option>Selecione:</option>
                    {this.state.data.normas.map((item)=><>
                    <option key={item.id}  value={item.id}>{item.titulo} - {item.cont}</option>
                    </>)}
                </select>
            </div>
            <br/>
       
  {this.state.data.doc_norma.length > 0 ? <>  <table className="table ntbl table-bordered">
        <thead>
          <tr>
            <th scope="col">NORMA</th>
            <th scope="col">REFERÊNCIA</th>
            <th scope="col">OPÇÃO</th>
          </tr>
          </thead>
          <tbody>
  {this.state.data.doc_norma.map((item)=><>
    <tr>
    <td>{item.titulo}</td>
    <td>{item.cont}</td>
    <td>     
      
    <button  class="btn btn-primary  ml-2" onClick={()=>{
            this.props.navigate(`normas/editar/doc/${window.location.pathname.split("/")[3]}/${item.id}`)     
    }}> <FontAwesomeIcon color='#fff' icon={faPen} /></button>


        <button  class="btn btn-danger  ml-2" onClick={async(e)=>{
                
                const result = await Confirm('Após a remoção, não será possível recuperar o dado.', 
                  'Tem certeza?');
               if(result){
                
                
                
                fetch(`${apiUrl}/normas/editar/doc/remover/`, {
                  method: 'POST',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+Cookies.get("auth_token"),
                  },
                  body:JSON.stringify({id:item.id})
                }).then(response => {
                  this.setState((prevState) => ({
                    data: {
                      ...prevState.data,
                      doc_norma: prevState.data.doc_norma.filter(norma => norma.id !== item.id)
                    }
                  }));
                })
                
                
                
                
                
               
               }
           

                
                
              
                
         
                
              }}> <FontAwesomeIcon color='#fff' icon={faTrash} /></button>
              
              
              </td>
  </tr>
  </>)}

  </tbody>
  </table></> : <>Não há nenhuma norma salva!</>}

                         </div>
                 
                         </div>
 
              
                    </div>


<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                          <h3>3 - CARACTERIZAÇÃO DA EMPRESA SOLICITANTE</h3>
                           
                         </div>
                         <div class="card-body">
<div style={{display:'table',margin:'auto'}}>
<img style={{width:200,height:140}} src={`${apiUrl}/img/${this.state.data.contratante.logo}`}/>
</div>

<div>
  <strong>Razão Social: </strong> {this.state.data.contratante.razao_social}
</div>
<div>
  <strong>CNPJ: </strong> {this.state.data.contratante.cnpj}
</div>

<div>
  <strong>Endereço: </strong> {this.state.data.contratante.endereco}
</div>
<div>
  <strong>Cep: </strong> {this.state.data.contratante.cep}
</div>

                          </div>
                        </div>
</div>


<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                          <h3>4- CARACTERIZAÇÃO DA EMPRESA EXECUTANTE</h3>
                           
                         </div>
                         <div class="card-body">
                         <div class="card-body">
<div style={{display:'table',margin:'auto'}}>
<img style={{width:200,height:140}} src={`${apiUrl}/img/${this.state.data.executante.logo}`}/>
</div>

<div>
  <strong>Razão Social: </strong> {this.state.data.executante.razao_social}
</div>
<div>
  <strong>CNPJ: </strong> {this.state.data.executante.cnpj}
</div>

<div>
  <strong>Endereço: </strong> {this.state.data.executante.endereco}
</div>
<div>
  <strong>Cep: </strong> {this.state.data.executante.cep}
</div>
                          </div></div>
                        </div>
</div>



<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>5- RESPONSÁVEIS TÉCNICOS</h3>
                           <Link to="/tecnicos" class="btn btn-primary float-right ml-2">  <FontAwesomeIcon color='#fff' icon={faUser} /> Técnicos</Link>

                         </div>
                         <div class="card-body">
                         
             <div class="form-group">
               
                <select class="form-control" value="Selecione" onChange={(event)=>{
 const selectedId = parseInt(event.target.value, 10);
 const selectedItem = this.state.data.tecs.find(item => item.id === selectedId);

 const check = this.state.data.doc_tecs.some(usuario => usuario.id === selectedId);
if(check){
  toast.warn(`O tecnico: ${selectedItem.nome} já foi inserido!`)
  return false;
}


fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/atualizar-tecs/`, {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+Cookies.get("auth_token"),
  },
  body:JSON.stringify({ids:`${selectedId}`})
}).then(response => {
  if(response.status == 200){
   
  response.json().then(data=>{
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        doc_tecs: [...prevState.data.doc_tecs, selectedItem]
      }
    }));
         toast.success("Normas atualizadas com sucesso!")
  })
       }
})







                }} id="exampleSelect">
                   <option>Selecione:</option>
                    {this.state.data.tecs.map((item)=><>
                    <option key={item.id}  value={item.id}>{item.nome} - {item.func}</option>
                    </>)}
                </select>
            </div>
            <br/>
       
  {this.state.data.doc_tecs.length > 0 ? <>  <table className="table ntbl table-bordered">
        <thead>
          <tr>
            <th scope="col">NOME</th>
            <th scope="col">FUNÇÃO</th>
            <th scope="col">OPÇÃO</th>
          </tr>
          </thead>
          <tbody>
  {this.state.data.doc_tecs.map((item)=><>
    <tr>
    <td>{item.nome}</td>
    <td>{item.func}</td>
    <td>       
       <button  class="btn btn-primary  ml-2" onClick={()=>{
             this.props.navigate(`/tecnicos/editar/doc/${window.location.pathname.split("/")[3]}/${item.id}`)     

              }}> <FontAwesomeIcon color='#fff' icon={faPen} /></button>


      <button  class="btn btn-danger  ml-2" onClick={async()=>{
                 
 const result = await Confirm('Após a remoção, não será possível recuperar o dado.', 
                    'Tem certeza?');
                 if(result){
                  
                  
                  
                  fetch(`${apiUrl}/tecnico/doc/remover/`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer '+Cookies.get("auth_token"),
                    },
                    body:JSON.stringify({id:item.id})
                  }).then(response => {
                    this.setState((prevState) => ({
                      data: {
                        ...prevState.data,
                        doc_tecs: prevState.data.doc_tecs.filter(norma => norma.id !== item.id)
                      }
                    }));
                  })
                  
                  
                  
                  
                  
                 
                 }
             
  
                  
                  
                
                  
           
                  
                







             



              }}> <FontAwesomeIcon color='#fff' icon={faTrash} /></button>
              
              </td>
  </tr>
  </>)}

  </tbody>
  </table></> : <>Não há nenhum ténico salvo!</>}

                         </div>
                      
                         </div>
 
              
</div>

<div className="col-12 mt-3" style={{display:'none'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>6- ESCOPO</h3>
                          
                         </div>
                         <div class="card-body">
                         <div>
                          <p>
                          O objetivo deste documento é a elaboração da apreciação de riscos de máquinas e equipamentos expondo todos os perigos existentes para que possam ser regularizados posteriormente, ou seja, a segurança absoluta não é um estado completamente acessível e, portanto, o objetivo é atingir o mais alto nível de segurança possível, levando-se em conta o estado da técnica.
                          A utilização dos métodos elencados nas páginas a seguir visa ações de melhoria a fim de mitigar ou eliminar os riscos existentes na operação baseando-se nas normas oficiais vigentes <strong>
                          NR12 (NR-12 – SEGURANÇA NO TRABALHO EM MÁQUINAS E EQUIPAMENTOS), ABNT NBR ISO 12100 – Segurança de máquinas – Princípios gerais de projeto – Apreciação e redução de riscos.
                          </strong>
                          </p>
                          </div>
                         </div>
                      
                         </div>
 
              
</div>

<div className="col-12 mt-3" style={{display:'none'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>7- MÉTODO DE AVALIAÇÃO PARA ESTIMATIVA DE RISCO CONFORME METODOLOGIA HAZARD RATING NUMBER (HRN)</h3>
                          
                         </div>
                         <div class="card-body">
                         <div>
                        <p>
                        Foi utilizado como ferramenta para quantificação e graduação do risco o método chamado Hazard Rating Number (HRN), ou seja, Número de Avaliação de Perigos. Este método é usado para classificar um risco entre Aceitável a Extremo, dando ao risco uma nota baseada em diversos fatores e parâmetros.

                        </p>
                          </div>
                          <div>
                          Os parâmetros utilizados por este método são:
                          </div>
                          <div>
                            <ul>
                              <li>A probabilidade de ocorrência (LO) de estar em contato com o risco</li>
                              <li>A frequência de exposição ao risco (FE) </li>
                              <li>O grau de severidade do dano (DPH) </li>
                              <li>O número de pessoas exposta ao risco (NP) </li>
                            </ul>
                          </div>
                          <div>
                            <p>
                            Para cada item mencionado acima é estabelecido um número que representa a variável de cálculo usada para encontrar o HRN do risco ou item avaliado. A formula aplicada para encontrar o nível de risco quantificado é a seguinte:
                            </p>
                          </div>

<div style={{textAlign:'center'}}>
  <strong>HRN = NP x DPH x FE x LO</strong>
</div>
<div>
                        <p>
                        Os parâmetros mencionados assim como as variáveis que cada um representa estão mencionados na tabela que se segue
                        </p>
                      </div>

                      <div style={{
                        display:'table',
                        margin:'auto'
                      }}>
                      <img src={pa}/>
                      </div>

<div>
<strong>RESULTADO DO CÁLCULO:</strong>
</div>

<div style={{
                        display:'table',
                        margin:'auto'
                      }}>
                      <img src={b}/>
                      </div>



                         </div>
                     
                         </div>
 
              
</div>

<div className="col-12 mt-3" style={{display:'none'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>8- DETERMINAÇÃO DA CATEGORIA</h3>
                          
                         </div>
                         <div class="card-body">
                         <div>Quando a máquina possuir a necessidade de uma solução de segurança com a utilização de sistemas eletrônicos (interfaces, sensores e atuadores) deve se atentar em qual categoria de segurança os riscos serão controlados. Para tais informações deve se levar em consideração os parâmetros de
  <strong>Severidade do ferimento, Frequência e/ou tempo de exposição ao perigo e Possibilidade de evitar o perigo (citados nas normas) ISSO 12100: 2013, ISO/TR 14.121-2: 2012 e NBR 14153: 2022, estas normas são utilizadas como base para a elaboração desta apreciação de riscos.</strong>
<div style={{marginTop:10,marginBottom:10,textAlign:'center'}}>
  <strong>NBR 14153: 2022</strong>
</div>
<div style={{display:'table',margin:'auto'}}>
<img src={f3} style={{with:600,height:'100%'}}/>
</div>

<div><strong>Os critérios de seleção:</strong></div>

<div style={{marginTop:10}}><strong>S – Severidade do ferimento </strong></div>
<ul>
          <li>S1: Ferimento leve (normalmente reversível)</li>
          <li>S2: Ferimento sério (normalmente irreversível) incluindo morte </li>
  
</ul>


<div style={{marginTop:10}}><strong>F – Frequência e/ou tempo de exposição ao perigo
</strong></div>
<ul>
          <li>F1: Raro a relativamente frequente e/ou baixo tempo de exposição</li>
          <li>F2: Frequente a contínuo e/ou tempo de exposição longo </li>
  
</ul>

<div style={{marginTop:10}}><strong>P – Possibilidade de evitar o perigo
</strong></div>
<ul>
          <li>P1: Possível sob condições específicas</li>
          <li>P2: Quase nunca possível. </li>
  
</ul>
</div>


<div className='ttl' style={{marginTop:30}}>
 As categorias de Segurança: <br/><br/>Categoria B</div>
        
<div className='dados'>
<div>As partes relacionadas à segurança devem ser projetadas de tal forma que resistam:</div>

<div style={{marginTop:10}}>Fadiga operacional prevista, como, por exemplo, a confiabilidade com respeito à capacidade e frequência de comutação;</div>

<div style={{marginTop:10}}> Influência do material processado ou utilizado no processo, como, por exemplo, detergentes em máquinas de lavar;
</div>
<div style={{marginTop:10}}>Outras influências externas relevantes, como, por exemplo, vibrações mecânicas, campos externos, distúrbios ou interrupção do fornecimento de energia.</div>
<div style={{display:'table',margin:'auto',marginTop:50}}>
  <img src={f4} style={{width:'100%',height:'100px'}}/>
</div>
</div>


<div>
  <strong>Categoria 1</strong>
  <div>
    <p>
    Os requisitos de B se aplicam.
    </p>
    <p>
    Princípios comprovados e componentes de segurança bem testados devem ser utilizados.
    </p>
    <p>
    As partes de sistemas de comando relacionadas à segurança, de categoria 1, devem ser projetadas e construídas utilizando-se componentes bem ensaiados e princípios de segurança comprovados.

    </p>
    <p>
    Um componente bem ensaiado para uma aplicação relacionada à segurança é aquele que tem sido:
- Largamente empregado no passado, com resultados satisfatórios em aplicações similares,

    </p>
    <p>
      ou
    </p>
  </div>
  <div>Construído e verificado utilizando-se princípios que demonstrem sua adequação e confiabilidade para aplicações relacionadas à segurança.</div>
<div style={{display:'table',margin:'auto',marginTop:10}}>
  <img src={f8} style={{width:'100%',height:200}}/>
</div>
<div>
  <strong>
  Categoria 2
  </strong>
</div>
<div>
As partes de sistemas de comando relacionadas à segurança, de categoria 2, devem ser projetadas de tal forma que sejam verificadas em intervalos adequados pelo sistema de comando da máquina.
</div>
<div>
As verificações das funções de segurança devem ser efetuadas:
</div>
<div>
- Na partida da máquina e antes do início de qualquer situação de perigo, e
</div>
<div>
- Periodicamente durante a operação, se a avaliação do risco e o tipo de operação mostrarem que isso é necessário.

</div>

<div style={{display:'table',margin:'auto',marginTop:10}}>
  <img src={f10} style={{width:'100%',height:200}}/>
</div>
<div style={{marginBottom:20}}>
  <strong>
  Categoria 3
  </strong>
</div>
<div>
Partes relacionadas à segurança de sistemas de comando de categoria 3 devem ser projetadas de tal forma que um defeito isolado, em qualquer dessas partes, não leve à perda das funções de segurança. Defeitos de modos comuns devem ser considerados, quando a probabilidade da
</div>


<div>
<div>ocorrência de tal defeito for significante. Sempre que razoavelmente praticável, o defeito isolado deve ser detectado durante ou antes da próxima solicitação da função de segurança.
  </div>
<div style={{display:'table',margin:'auto',marginTop:10}}>
  <img src={f11} style={{width:'100%',height:200}}/>
</div>
<div>
  <strong>
  Categoria 4
  </strong>
</div>
<div>
Partes de sistemas de comando relacionadas à segurança, de categoria 4, devem ser projetadas de tal forma que:</div>
<div>
- Uma falha isolada em qualquer dessas partes relacionadas à segurança não leve à perda das funções de segurança, e
</div>
<div>
- A falha isolada seja detectada antes ou durante a próxima atuação sobre a função de segurança, como, por exemplo, imediatamente, ao ligar o comando, ao final do ciclo de operação da máquina.
</div>
<div>

Se essa detecção não for possível, o acúmulo de defeitos não pode levar à perda das funções de segurança.
</div>

<div style={{display:'table',margin:'auto',marginTop:10}}>
  <img src={f12} style={{width:'100%',height:200}}/>
</div>
</div>
<div>
<div className='centro'>
    <img src={f5} style={{width:'100%',height:'60%'}}/>
  </div>
  <div>Este gráfico de riscos pode ser utilizado para estimar um índice de riscos principalmente para situações perigosas que podem induzir danos agudos.</div>
 <div style={{padding:15}}>
  <div><strong>A. Um índice de risco de 1 e 2 corresponde a um risco baixo;</strong></div>
  <div><strong>B. Um índice de risco de 3 a 4 corresponde a um risco médio;</strong></div>
  <div><strong>C. Um índice de risco de 5 e 6 corresponde a um risco alto;</strong></div>
 </div>
 <div>Depois de uma análise dos possíveis meios para reduzir o risco, ele é estimado novamente para o projeto final utilizando o mesmo gráfico de riscos da mesma forma que para o projeto inicial. </div>
 <div className='centro'>
    <img src={f6} style={{width:'100%',height:'100%'}}/>
  </div>
  <div>
  <div>         Estabelecendo uma relação de semelhança entre HRN metodologia aplicada e as normas técnicas <strong>NBR 14153: 2022, ISO/TR 14.121-2: 2018 e ISO 12.100: 2013</strong> é possível chegar a uma conclusão para a determinação de categoria de segurança:</div>
        
        <div style={{marginTop:10,marginBottom:10}}>Pode se considerar um risco baixo a categoria 1, e um risco médio quando se encaixa na categoria 2 e um risco alto, quando está classificado na categoria 3 ou 4.</div>
        <div style={{textAlign:'center'}}>
    <strong>    ISO 13849-1: 2019</strong>
        </div>
        <div>
        Qualquer que seja o tipo de dispositivo de proteção escolhido, deve-se lembrar que um “sistema relacionado à segurança” pode conter muitos elementos incluindo o dispositivo de proteção, fiação, dispositivo de chaveamento e às vezes, peças do sistema de controle operacional da máquina. Todos esses elementos do sistema (incluindo proteções, montagem, fios, etc.) devem ter características de desempenho adequadas, relevantes ao seu princípio de projeto e tecnologia. Os <strong>IEC/EM 62061 e EM ISO 13849-1</strong> classificam os níveis hierárquicos de desempenho das peças relacionadas à segurança dos sistemas de controle e proporcionam métodos de avaliação de risco em seus anexos para determinar os requisitos de integridade de um sistema de proteção. O <strong>EN ISO 13849-1: 2015</strong> fornece um gráfico de risco aumentado em seu anexo A.
        </div>
        <div>


        <div className='centro'>
    <img src={f7} style={{width:'100%',height:'100%'}}/>
  </div>

<div><strong>Onde:</strong></div>
<div style={{padding:10}}>
<div><strong>1-</strong> Ponto de partida para a avaliação de redução de risco.</div>
<div><strong>L-</strong> Baixa contribuição para a redução do risco.</div>
<div><strong>H-</strong> Alta contribuição para a redução do risco.</div>
<div><strong>PLr-</strong> Performance Level requerido.</div>

</div>
<div style={{textAlign:'center'}}><strong>S – Severidade do ferimento</strong></div>
<div style={{padding:10}}>
<div><strong>S1-</strong>Ferimento leve (normalmente reversível)</div>
<div><strong>S2-</strong> Ferimento sério (normalmente irreversível) incluindo morte.</div>
</div>




<div style={{textAlign:'center'}}><strong>F – Frequência e/ou tempo de exposição ao perigo.</strong></div>
<div style={{padding:10}}>
<div><strong>F1-</strong>Raro a relativamente frequente e/ou baixo tempo de exposição.</div>
<div><strong>F2-</strong> Frequente a contínuo e/ou tempo de exposição longo.</div>
</div>

<div style={{textAlign:'center'}}><strong>P – Possibilidade de evitar o perigo.</strong></div>
<div style={{padding:10}}>
<div><strong>P1-</strong>Possível sob condições especificas.</div>
<div><strong>P2-</strong>Possível sob condições especificas.</div>
</div>

<div style={{textAlign:'center'}}><strong>EM/IEC 62061 – SIL</strong></div>

<div>
<div>
  Descreve a quantidade de risco a ser reduzido e a capacidade de um sistema de controle de reduzir esse risco em termos de SIL (Nível de Integridade de Segurança). Há três SILs usados no setor de máquinas, o SIL 1 é o menor e o SIL 3 é o maior. Como o termo SIL é aplicado do mesmo modo em outros setores industriais, como o petroquímico, geração de energia e ferrovias, o IEC/EM 62061 é bastante útil quando a máquina é usada nesses setores.

  </div>
  <div className='centro'>
    <img src={g1} style={{width:'100%',height:300}}/>
  </div>

<div>
Relação entre os dois padrões e explica como a equivalência pode ser obtida entre PL (Nível de Desempenho) do EM ISO 13849-1 e SIL (Nível de Integridade de Segurança) do IEC EM 62061, ambos no nível de sistema e subsistema. Para demonstrar que ambos os padrões fornecem resultados equivalentes, o relatório exibe um sistema de segurança como exemplo, calculado de acordo com as metodologias de ambos os padrões.

</div>

<div style={{marginTop:20}}>
Em geral, quando PLe se faz necessário para a função de segurança ser implementada por um sistema de controle de segurança, não é normal depender somente de exclusões de falhas para atingir esse nível de desempenho. Isso depende da tecnologia usada e do ambiente operacional pretendido. Portanto, é essencial que o projetista tome cuidado adicional com o uso de exclusões de falhas à medida que a exigência de PL aumenta.
</div>



<div style={{textAlign:'center',marginBottom:25}}><strong>RELAÇÃO ENTRE NBR 14153 E A ISO 13849-1: 2019</strong></div>

<div>A norma ISO 13849-1 usa dados quantitativos de confiabilidade como parte do cálculo do PL alcançado pelas partes relacionadas com a segurança de um sistema de controle. Estes dados devem ter confiabilidade assegurada, pois estamos falando, por exemplo, de um MTTFd alto (tempo médio para ocorrência da falha perigosa) na faixa de 30 a 100 anos.</div>



<div>Na União Europeia a confiabilidade dos componentes de segurança está coberta pela avaliação da conformidade expressa na Diretiva Máquinas, mesmo assim, a migração dos conceitos da EM 954</div>
<div>
  <br/>
<div>
  (base da ABNT NBR 14153) para ISSO 13849 passou por um longo processo de maturação, inclusive com a convivência por mais de 2 anos, de opção para aplicação de qualquer uma das duas normas.
  </div>

  <div style={{textAlign:'center',marginBottom:25,marginTop:25}}><strong>RELAÇÃO ENTRE CATEGORIAS, DC E MTTFD DE CADA CANAL E O PL.</strong></div>


<div>
Conclui-se que para o alcance do nível de performance requerido, não basta apenas a arquitetura do sistema (categorias), mas também trabalhar as variáveis como a confiabilidade dos dados para as partes constituintes do sistema expresso no tempo médio para falha perigosa (MTTFd), a cobertura de diagnostico (DC) que representa a quantidade de monitoramento de falhas no sistema, proteção contra falhas de causa comum, proteção contra falhas sistemáticas e, sempre que necessário, requisitos específicos de software.
</div>
<br/>
<div>
O gráfico e a tabela abaixo procuram estabelecer, de forma simplificada, a relação entre as categorias de segurança e PL em função das variáveis elencadas.
</div>
  <div className='centro'>
    <img src={g2} style={{width:'100%',height:300}}/>
  </div>

  <div className='centro'>
    <img src={g3} style={{width:'80%',height:250}}/>
  </div>
<br/>
<div className='centro'>
    <img src={g4} style={{width:'80%',height:250}}/>
  </div>
</div>
</div>


        </div>
  </div>
</div>

</div>



                         </div>

                         </div>
                         </div>



<div className="col-12 mt-3"  style={{display:'block'}}>
                    <div className="card">
                   

                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>9 - DOCUMENTAÇÃO DO EQUIPAMENTO</h3>
                          
                         </div>
                         <div class="card-body">

<table className="table ntbl table-bordered">
<thead> 
<tr>
            <th rowspan="2">DOCUMENTOS</th>
            <th colspan="3">STATUS</th>
            <th rowspan="2">OBSERVAÇÕES</th>
</tr>
<tr>
            <th>OK</th>
            <th>NÃO OK</th>
            <th>N.A.</th>
        </tr>
 </thead>
  <tbody>
  {[
  "ANOTAÇÃO DE RESPONSABILIDADE TÉCNICA",
  "CHECKLIST DE MANUTENÇÃO PREVENTIVA",
  "CHECKLIST DE SEGURANÇA",
  "ART DIAGRAMA DE CIRCUITOS ELÉTRICOS",
  "DIAGRAMA HIDRÁULICO",
  "DIAGRAMA PNEUMÁTICO",
  "INSTRUÇÕES DE TRABALHO",
  "LAUDO DE ATERRAMENTO",
  "LAUDO DE TEMPO DE PARADA",
  "MANUAL DE INSTRUÇÕES DE MANUTENÇÃO",
  "MANUAL DE INSTRUÇÕES DE SEGURANÇA",
  "MANUAL DE INSTRUÇÕES OPERACIONAIS",
  "PLAQUETA DE IDENTIFICAÇÃO",
  "ART PROJETO MECÂNICO"
].map((item,index)=>{
var check = this.state.form.find(u=> u.e === `${index}`);
if(check != undefined){
var find = check;
}else{
var find = {}
}
return(<>

  <tr>
              <td>{item} 
  
  {index} 
  
  
              </td>
              <td>
              <label>
              
             <input type="radio"

              checked={find.marker === "0"}
          
              onChange={(e) => this.handleOpc(e, index)} name={`${index}`} value={0}/>
              </label>
                 
              </td>
  
              <td class="highlight">
            
              <input type="radio" 
              checked={find.marker === "1"}
              onChange={(e) => this.handleOpc(e, index)} name={`${index}`} value={1}/>
              </td>
  
              <td>
              <input onChange={(e) => this.handleOpc(e, index)} 
                      checked={find.marker === "2"}
              type="radio" name={`${index}`} value={2}/>
              </td>
              
              <td class="highlight">
             
                <textarea value={find.obs}
                onChange={(e)=>{

                 

                  this.setState(prevState => ({
                    form: prevState.form.map(item =>
                      item.e === `${index}` ? { ...item, obs:`${e.target.value}`} : item
                    )
                  }));


                  this.setState(prevState => ({
                    Opc: {
                      ...prevState.Opc,
                      [index]: {
                        ...prevState.Opc[index],
                        o:e.target.value
                      }
                    }
                  }));



                }}
                placeholder='Digite:'/>
              </td>
          </tr>
    </>)
})}
  </tbody>
</table>

                          
                         </div>
                         <div class="card-footer">
                         <button type="submit" class="btn btn-primary float-right ml-2" onClick={(e)=>{

var itens = this.state.Opc;

if(itens.length == 0){
  return;
}


e.target.classList.add("load")

fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/form/`, {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+Cookies.get("auth_token"),
  },
  body:JSON.stringify({itens})
}).then(response => {
e.target.classList.remove("load")
toast.success("Salvo com sucesso!")
})




               }}> <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar</button>
                         </div>
                         </div>
 
              
</div>



<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>10 - VISÃO GERAL DA MÁQUINA</h3>
                           <div className='custom-file'>
                           
                           
                           {/*<input type="file" multiple onChange={this.handleFileChange} />*/}
                       
                           </div>
                            </div>
                         <div class="card-body">
                         <TextView type="text"
 code="dfts6h51dfh5s1" 
 data={this.state.TextView}
/>
<br/>
<div class="prev-imgs">

{this.state.images.length > 0 ? <>

          {this.state.images.map((item) =>(<>
          <div class="pi">
          <img src={`${apiUrl}/img/`+item.img} alt="not loaded"/>
          <button className='rmver' onClick={async()=>{
             const result = await Confirm('Após a remoção, não será possível recuperar o dado.', 
              'Tem certeza?');
              if(result){
                fetch(`${apiUrl}/solicitante/deletar/imagem/${item.id}/`+window.location.pathname.split("/")[3], {
                  method: 'DELETE',
     
                 }).then(response => {
if(response.status == 200){
  
  this.setState({
    images:this.state.images.filter(e => e.id !== item.id)
  })


}
                 })
              }
          }}>
            <FontAwesomeIcon color='#fff' icon={faClose}/>
          </button>
          </div>
     
          </>))}
<br/>
          <div style={{display:'table',margin:'auto'}}>
          <button className='btn btn-primary btn-upl' onClick={()=>{
                            this.setState({upload5:true})
                            }}><FontAwesomeIcon color='#fff' icon={faImage} />  Adicionar imagem</button>
          </div>
        
</> : <>
<button className='btn btn-primary btn-upl' onClick={()=>{
                            this.setState({upload5:true})
                            }}><FontAwesomeIcon color='#fff' icon={faImage} />  Adicionar imagem</button>
</>}






</div>
                         </div>
                         
                         </div>
 
              
</div>

<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>11-DEFINIÇÃO DA CATEGORIA DE SEGURANÇA </h3>
                           
                            </div>
                            <div class="card-body">
                     
<div class="diagram">
<Treetest save={this.state.diagram} node={{
  name: 'Start',
  id:0,
  children: [
    {
      name: 's1',
      id:1,
      children: [
        {
          name: 'f1',
          id:2,
          children: [
            { name: 'p1',id:3 },
            { name: 'p2',id:4 },
          ],
        },
        { name: 'f2',
          id:5,
          children: [
            { name: 'p1',id:6 },
            { name: 'p2',id:7 },
          ],

        },
      ],
    },
    {
      name: 's2',
      id:8,
      children: [
        {
          name: 'f1',
          id:9,
          children: [
            { name: 'p1',id:10 },
            { name: 'p2',id:11 },
          ],
        },
        {
          name: 'f2',
          id:12,
          children: [
            { name: 'p1',id:13 },
            { name: 'p2',id:14 },
          ],
        },
      ],
    },
  ],

}}   onSelect={(e,type,id)=>{

  if(this.state.diagram.includes(`${e}-${id}`) == false){
  this.setState((prevState) => {


   
    if (prevState.diagram.includes(e)) {
      return prevState;
    } else {
      return {
        diagram: [...prevState.diagram, `${e}-${id}`]
      };
    }
  
  });

  }else{
 
    this.setState({
      diagram:this.state.diagram.filter(norma => norma !== `${e}-${id}`)
    })
  
  }

}}
allowSelect={true}/>
<div className='coluna'>
<div className={
["s1-1","f1-2","p1-3"].every((value, index) => value === this.state.diagram[index]) ? "a selected" : "a"
}>
  A
</div>
<div className={
["s1-1","f1-2","p2-4"].every((value, index) => value === this.state.diagram[index]) ||
["s1-1","f2-5","p1-6"].every((value, index) => value === this.state.diagram[index])
? "b selected" : "b"
}>
  B
</div>
<div className={
["s1-1","f2-5","p2-7"].every((value, index) => value === this.state.diagram[index]) ||
["s2-8","f1-9","p1-10"].every((value, index) => value === this.state.diagram[index])
? "c selected" : "c"
}>
  C
</div>

<div className={
["s2-8","f1-9","p2-11"].every((value, index) => value === this.state.diagram[index]) ||
["s2-8","f2-12","p1-13"].every((value, index) => value === this.state.diagram[index])
? "d selected" : "d"
}>
  D
</div>
<div className={
["s2-8","f2-12","p2-14"].every((value, index) => value === this.state.diagram[index]) ? "e selected" : "e"
}>
  E
</div>
</div>

</div>


<div><strong>Onde:</strong></div>
<div style={{padding:10}}>
<div><strong>1-</strong> Ponto de partida para a avaliação de redução de risco.</div>
<div><strong>L-</strong> Baixa contribuição para a redução do risco.</div>
<div><strong>H-</strong> Alta contribuição para a redução do risco.</div>
<div><strong>PLr-</strong> Performance Level requerido.</div>

</div>
<div style={{textAlign:'center'}}><strong>S – Severidade do ferimento</strong></div>
<div style={{padding:10}}>
<div><strong>S1-</strong>Ferimento leve (normalmente reversível)</div>
<div><strong>S2-</strong> Ferimento sério (normalmente irreversível) incluindo morte.</div>
</div>




<div style={{textAlign:'center'}}><strong>F – Frequência e/ou tempo de exposição ao perigo.</strong></div>
<div style={{padding:10}}>
<div><strong>F1-</strong>Raro a relativamente frequente e/ou baixo tempo de exposição.</div>
<div><strong>F2-</strong> Frequente a contínuo e/ou tempo de exposição longo.</div>
</div>

<div style={{textAlign:'center'}}><strong>P – Possibilidade de evitar o perigo.</strong></div>
<div style={{padding:10}}>
<div><strong>P1-</strong>Possível sob condições especificas.</div>
<div><strong>P2-</strong>Possível sob condições especificas.</div>
</div>



                            </div>
                         <div class="card-footer">
                         <button type="submit" class="btn btn-primary float-right ml-2" onClick={(e)=>{

if(this.state.diagram.length < 3){
  toast.error("Selecione todos itens do diagrama!")
  return false;
}
e.target.classList.add("load")
var d = this.state.diagram.join(",");
fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/diagrama/`, {
  method: 'POST',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+Cookies.get("auth_token"),
  },
  body:JSON.stringify({d})
}).then(response => {
e.target.classList.remove('load')
if(response.status == "200"){
  response.json().then(data=>{
    toast.success(data.r)
  })
}
}).catch(err=>{
  toast.error("Falha ao enviar!")
})





                         }}> <FontAwesomeIcon color='#fff' icon={faSave} /> Salvar</button>
                         </div>
                         </div>
 
              
</div>

<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>12-AVALIAÇÃO – APRECIAÇÃO DE RISCOS</h3>
                           
                            </div>
                         <div class="card-body">

<div style={{textAlign:'center'}}>
  <strong>
  12.1 – Arranjo físico das instalações – Geral
  </strong>
</div>
<div>
  <p>
  Nos locais de instalações de máquinas e equipamentos, as áreas de circulação devem ser devidamente demarcadas e em conformidade com as normas técnicas oficias vigentes.
  </p>
</div>

<div class="form-group mt-2">

<Adcs code="354f65h4fg" data={this.state.apc} />
                         
</div>

<div>
  <Grouplist data={this.state.gplist} code="12.1"/>
</div>
                          

  <RiskAppreciationTable
  code="hrn-1"
  data={this.state.FormHrn}
  resp={(value)=>{
    this.setState(prevState => ({
      AtMlhs: [...prevState.AtMlhs,value],
    }));
  }}
  title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>

  <div class="form-group mt-2">




<Apc code="87rag454df" data={this.state.apc}/>




  
  </div>

  <RiskAppreciationTable
  code="hrn-2"

  

  resp={(value)=>{
    this.setState(prevState => ({
      AtMlhs: [...prevState.AtMlhs,value],
    }));
  }}

  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>
<div>
 </div>

                         </div>
                         
                         </div>
 
        
</div>
      


<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>12.2 Instalações e dispositivos elétricos – Geral </h3>
                       



                            </div>
                      

<div class="card-body">
  

<p>
  Os circuitos elétricos de comando e potência das máquinas e equipamentos devem ser projetados e mantidos de modo a prevenir, por meios seguros, os perigos de choque elétrico, incêndio, explosão e outros tipos de acidentes, conforme previsto nas normas técnicas oficiais e, na falta dessas, nas normas internacionais aplicáveis.
  </p>

  
<div class="form-group mt-2">
               
<Adcs code="s635d1rt6556" data={this.state.apc} />
  

                          
                                  </div>
                          

{this.state.grupoi.length > 0 ? <>
<br/>

{this.state.grupoi.map((item,index)=>{
  var elemnt = item;
  var el = this;
  return(<>
  <>
  <div className="card" style={{marginTop:15}}>
                  

  <div class="card-header d-flex justify-content-between">
                           <h3>Grupo: {index+1}</h3>
                         <div style={{display:'flex'}}>




                          
                         
                          <button className='btn btn-primary' style={{
                            marginRight:10
                          }} onClick={async(e)=>{
                        
                        
                            this.setState({ModalOpen:true,itensModal:{item,index}})
                           
                            return false;
                            const result = await CustomDialog(
                              <>
                
                        <div style={{margin:15}}>
                        <input type="text" class="form-control form-control-sm" placeholder='Titulo:' />
                        <br/>
                        <input class="form-control" type="file"  onChange={(event)=>{

const file = event.target.files[0];
    
if (file) {
  const isImage = file.type.startsWith('image/');
  if(isImage == false){
    event.target.value = '';
toast.error("Somente imagens são aceitas!")
    return false;
  }

}

                        }}/>
                      <button onClick={()=>{
                        this.props.dialog.close()
                      }}>
                        fehcar
                      </button>
                        </div>
                    
                     
                              </>,
                              {
                                title: 'Adicionar imagem',
                                showCloseIcon: true,
                              }
                            );
                          
    
    
    
                            /*
                            
                                
                             */    
                                  
    
    
    
    
    
                                  
    
    
    
                                  
                                }}>
                          <FontAwesomeIcon icon={faImage} color='#fff'/> Adicionar imagem</button>
                       
                          
                          <button className='btn btn-danger' onClick={async()=>{
              if(item.list.length > 0){
                    var r = await   Confirm(`Ao selecionar o opção ok esse grupo será deletado!`,'Atenção!')
                       
                    if(r){
                      const id = toast.loading("Deletando")
                      fetch(`${apiUrl}/solicitante/${item.code}/delete/group-image/`, {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer '+Cookies.get("auth_token"),
                        },
                        body:JSON.stringify({d:new Date()})
                      }).then(response => {
                        if(response.status == 200){

                          toast.update(id, { render:"Grupo deletado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
             
                          this.setState({
                            grupoi:this.state.grupoi.filter(i => i.code !== item.code)
                            })
  
                        }
                      })
                    }
                  
                  }else{
                        
                        const id = toast.loading("Deletando")
                        fetch(`${apiUrl}/solicitante/${item.code}/delete/group-image/`, {
                          method: 'DELETE',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer '+Cookies.get("auth_token"),
                          },
                          body:JSON.stringify({d:new Date()})
                        }).then(response => {
                          if(response.status == 200){

                            toast.update(id, { render:"Grupo deletado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
               
                            this.setState({
                              grupoi:this.state.grupoi.filter(i => i.code !== item.code)
                              })
    
                          }
                        })





                       }
                      
                        }}>
                          <FontAwesomeIcon icon={faTrash} color='#fff'/> Deletar
                        </button>
                         </div>
                     

                            </div>

<div class="card-body">
<div className='gp-imgs'>




{this.state.grupoi[index].list.length > 0 ? <>
{this.state.grupoi[index].list.map((item)=><div class="view">
<img src={`${apiUrl}/img/${item.img}`} alt="imagem nao carregada!"/>
<div class="ac">
  <div class="arb">
  <button className='b1' onClick={async()=>{

const result = await CustomDialog(
  <>
  <ImageMarker imageSrc={`${apiUrl}/img/${item.img}`} item={item} onFinish={(data)=>{

this.setState((prevState) => {
  const updatedGroupi = [...prevState.grupoi];
  
  // Encontre o índice do grupo alvo
  const targetIndex = updatedGroupi.findIndex(e => e.code === elemnt.code);
  
  if (targetIndex !== -1) {
    // Encontre o índice do item dentro do grupo
    const itemIndex = updatedGroupi[targetIndex].list.findIndex(i => i.id === item.id);
    
    if (itemIndex !== -1) {
      // Atualize a imagem do item
      updatedGroupi[targetIndex].list[itemIndex] = {
        ...updatedGroupi[targetIndex].list[itemIndex],
        img: data.img // Substitua `newImage` pela nova imagem que você deseja definir
      };
    }
  }
  
  return { grupoi: updatedGroupi };
});

  }}/>
  </>,
  {
    title: 'Editar imagem',
    showCloseIcon: true,
  }
);




  
  }}>
  <FontAwesomeIcon color='#fff' icon={faPen}/>
</button>
<button  className='b2' onClick={async()=>{




var r  = await Confirm('A exclusão da imagem é permanente e não pode ser desfeita','Atenção?')
 if(r){
  fetch(`${apiUrl}/solicitante/deletar/imagem/groupi/${item.id}/`, {
    method: 'DELETE',

   }).then(response => {

if(response.status == 200){
  this.setState((prevState) => {
    const updatedGroupi = [...prevState.grupoi];
    const targetIndex = updatedGroupi.findIndex(e => e.code === elemnt.code);
    if (targetIndex !== -1) {
      updatedGroupi[index].list = updatedGroupi[targetIndex].list.filter(i => i.id !== item.id);
    }
    return { grupoi: updatedGroupi };
  });
}
   

})



 }
}}>
  <FontAwesomeIcon color='#fff' icon={faTrash}/>
</button>
  </div>

</div>
</div>)}
</> : <>
Adicione uma imagem para ser exibida
</>}


</div>

</div>

                       </div>
</>
  </>)
}
)}

<div style={{
  display:'table',
  margin:'auto',
  marginTop:15
}}>
<button className='btn btn-primary' onClick={()=>{
                            

                       
                            const id = toast.loading("Criando grupo")
 
                          fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/create/group-image/`, {
                           method: 'POST',
                           credentials: 'include',
                           headers: {
                             'Content-Type': 'application/json',
                             'Authorization': 'Bearer '+Cookies.get("auth_token"),
                           },
                           body:JSON.stringify({d:new Date()})
                         }).then(response => {
                       
                        response.json().then((data)=>{
                          toast.update(id, { render:"Grupo criado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
                          this.setState(prevState => ({
                               grupoi: [
                                 ...prevState.grupoi,
                                 {code:data.code,list: [] }  // Adiciona um novo item com imgs como array vazio
                               ]
                             })); 
                        })
                   
                         })
 
 
 
 
                          



                        
                        }}>
                          <FontAwesomeIcon icon={faPlus} color='#fff'/> Adicionar grupo
</button>
</div>

</> : <div style={{
 
 display:'table',
 margin:'auto',
 marginTop:15,
 marginBottom:0
  
 
}}>


<button className='btn btn-primary' onClick={()=>{
                            

                       
                            const id = toast.loading("Criando grupo")
 
                          fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/create/group-image/`, {
                           method: 'POST',
                           credentials: 'include',
                           headers: {
                             'Content-Type': 'application/json',
                             'Authorization': 'Bearer '+Cookies.get("auth_token"),
                           },
                           body:JSON.stringify({d:new Date()})
                         }).then(response => {
                          response.json().then((data)=>{
                            toast.update(id, { render:"Grupo criado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
                            this.setState(prevState => ({
                                 grupoi: [
                                   ...prevState.grupoi,
                                   {code:data.code,list: [] }  // Adiciona um novo item com imgs como array vazio
                                 ]
                               })); 
                          })
                         })
 
 
 
 
                          



                        
                        }}>
                          <FontAwesomeIcon icon={faPlus} color='#fff'/> Adicionar grupo
</button>



</div>}


</div>



                         </div>
 
              
</div>




<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3></h3>
                       
                 
                     


                            </div>
                      

                            <div class="card-body">




<div className='itens-a8'>
  
<div>
<RiskAppreciationTable 
                            code="hrn-3"
                            data={this.state.FormHrn}
                            title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DAS MELHORIAS"/>
<br/>
<Apc code="6dgdhtfghgf" data={this.state.apc}/>
  <br/>
 
</div>

<RiskAppreciationTable 
                            code="hrn-4"
                            data={this.state.FormHrn}
                            title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DAS MELHORIAS"/>


<br/>
<div style={{textAlign:'center'}}>
  <strong>12. 3 Dispositivo de partida, acionamento e parada – Geral</strong>
</div>
<br/>

<Adcs code="sdtdfj6556" data={this.state.apc} />


<div>
  <Grouplist data={this.state.gplist} code="12.3"/>
</div>


</div>
                           
                            
                            </div>



                         </div>
 
              
</div>




<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3></h3>
                       
                      


                            </div>
                      

                            <div class="card-body">


<RiskAppreciationTable 
code="hrn-5"
data={this.state.FormHrn}
resp={(value)=>{
  this.setState(prevState => ({
    AtMlhs: [...prevState.AtMlhs,value],
  }));
}}
title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>
<br/>
<div>
<Apc code="6dgyj5616fd5" data={this.state.apc}/>
</div>

<TextView type="text"
 code="xf5d4h" 
 data={this.state.TextView}
/>



                            <RiskAppreciationTable
                            code="hrn-6"
                            data={this.state.FormHrn}
                            title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DAS MELHORIAS"/>
<br/>
<div>

</div>
<br/>




                    

             
                           
                            
                            </div>



                         </div>
 
              
</div>


<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>12.4    Sistemas de Segurança</h3>
                       
                       
                    

                            </div>
                      

<div class="card-body">
<Adcs code="cfgjyghygjh4" data={this.state.apc} />
{
  this.state.group_2.length > 0 ? <>

  {this.state.group_2.map((item,index)=>{

    var e = item.itens;


    return(<>
     <div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
                   
                           
                           <div class="card-header d-flex justify-content-between">
                           <h3>Grupo: {index+1}</h3>
                       
                       
                      <button className='btn btn-danger' onClick={async()=>{
             

                    var r = await   Confirm(`Ao selecionar o opção ok esse grupo será deletado!`,'Atenção!')
                
                    if(r){
                      const id = toast.loading("Deletando")
                      fetch(`${apiUrl}/gp2/${item.code}`, {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer '+Cookies.get("auth_token"),
                        },
                        body:JSON.stringify({d:new Date()})
                      }).then(response => {
                        if(response.status == 200){

                          toast.update(id, { render:"Grupo deletado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
             
                          this.setState({
                            group_2:this.state.group_2.filter(i => i.code !== item.code)
                            })
              
  
                        }
                      })
                    }
                        
                    





                       
                      
                        }}>
<FontAwesomeIcon icon={faTrash}/>
                      </button>


                            </div>
                      

<div class="card-body">



<div style={{display:'flex'}}>
{e.img1 != null ? <>
  <div class="area-8">
  <div class="preview">
  <div class="edit">
    
    <input type="file" onChange={(event)=>{
this.UploadGp2(event,{
  index,
  code:item.code,
  type:'img1'
})}
    }/>
    <FontAwesomeIcon icon={faPen} color='#fff'/>


  </div>
  <img  src={`${apiUrl}/img/${e.img1}`} />
  </div>
</div>

</> : <>
  <div class="area-8">
  <div class="add">
    <input type="file" onChange={(event)=>{
this.UploadGp2(event,{
  index,
  code:item.code,
  type:'img1'
})}
    }/>
    <FontAwesomeIcon icon={faPlus} color='#282828'/>
  </div>
  </div>

</>}



{e.img2 != null ? <>



  <div class="area-8">
  <div class="preview">
  <div class="edit">
    
    <input type="file" onChange={(event)=>{
this.UploadGp2(event,{
  index,
  code:item.code,
  type:'img2'
})}
    }/>
    <FontAwesomeIcon icon={faPen} color='#fff'/>


  </div>
  <img  src={`${apiUrl}/img/${e.img2}`} />
  </div>
</div>


</> : <>
  <div class="area-8">
  <div class="add">
    <input type="file"  onChange={(event)=>{
this.UploadGp2(event,{
  index,
  code:item.code,
  type:'img2'
})}
    }/>
    <FontAwesomeIcon icon={faPlus} color='#282828'/>
  </div>
  </div>

</>}


</div>
<br/>
<textarea className='form-control' value={e.txt1} onChange={(e)=>{
   this.setState(prevState => {
    const novoGrupo2 = [...prevState.group_2];
    novoGrupo2[index].itens['txt1'] = e.target.value;

    return { group_2: novoGrupo2 };
  });
}} onBlur={(e)=>{
  fetch(`${apiUrl}/text-gp2`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body:JSON.stringify({
      text:e.target.value,
      code:item.code,
      type:'txt1'
    })
  }).then(response => {
if(response.status == 200){
  toast.success("Salvo!")
}
  })
}}  placeholder='Digite:' />
<br/>

<div style={{display:'flex'}}>
{e.img3 != null ? <>
  <div class="area-8">
  <div class="preview">
  <div class="edit">
    
    <input type="file" onChange={(event)=>{
this.UploadGp2(event,{
  index,
  code:item.code,
  type:'img3'
})}
    }/>
    <FontAwesomeIcon icon={faPen} color='#fff'/>


  </div>
  <img  src={`${apiUrl}/img/${e.img3}`} />
  </div>
</div>

</> : <>
  <div class="area-8">
  <div class="add">
    <input type="file" onChange={(event)=>{
this.UploadGp2(event,{
  index,
  code:item.code,
  type:'img3'
})}
    }/>
    <FontAwesomeIcon icon={faPlus} color='#282828'/>
  </div>
  </div>

</>}



{e.img4 != null ? <>



  <div class="area-8">
  <div class="preview">
  <div class="edit">
    
    <input type="file" onChange={(event)=>{
this.UploadGp2(event,{
  index,
  code:item.code,
  type:'img4'
})}
    }/>
    <FontAwesomeIcon icon={faPen} color='#fff'/>


  </div>
  <img  src={`${apiUrl}/img/${e.img4}`} />
  </div>
</div>


</> : <>
  <div class="area-8">
  <div class="add">
    <input type="file"  onChange={(event)=>{
this.UploadGp2(event,{
  index,
  code:item.code,
  type:'img4'
})}
    }/>
    <FontAwesomeIcon icon={faPlus} color='#282828'/>
  </div>
  </div>

</>}


</div>
<br/>

<textarea className='form-control' value={e.txt2} onChange={(e)=>{
   this.setState(prevState => {
    const novoGrupo2 = [...prevState.group_2];
    novoGrupo2[index].itens['txt2'] = e.target.value;

    return { group_2: novoGrupo2 };
  });
}} onBlur={(e)=>{
  fetch(`${apiUrl}/text-gp2`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body:JSON.stringify({
      text:e.target.value,
      code:item.code,
      type:'txt2'
    })
  }).then(response => {
if(response.status == 200){
  toast.success("Salvo!")
}
  })
}}  placeholder='Digite:' />


  </div>
  
  </div>
  
  </div>
    </>)

  })}

<div style={{display:'table',margin:'auto',marginTop:15}}>
  
  <button className='btn btn-primary' onClick={()=>{
                            

                       
                            const id = toast.loading("Criando grupo")
 
                          fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/create/group-image-2/`, {
                           method: 'POST',
                           credentials: 'include',
                           headers: {
                             'Content-Type': 'application/json',
                             'Authorization': 'Bearer '+Cookies.get("auth_token"),
                           },
                           body:JSON.stringify({d:new Date()})
                         }).then(response => {
                          
                          response.json().then(data=>{

                            toast.update(id, { render:"Grupo criado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
                       
                       
                            this.setState(prevState => ({
                              group_2: [
                                ...prevState.group_2,
                                {code:data.token,itens:{} }  // Adiciona um novo item com imgs como array vazio
                              ]
                            })); 
                            
                          })
                          
                         
                         })
 
 
 
 
                          



                        
                        }}>
                          <FontAwesomeIcon icon={faPlus} color='#fff'/> Adicionar grupo
                        </button>

  
  
  </div>

  </> : <div style={{display:'table',margin:'auto',marginTop:15}}>
  
  <button className='btn btn-primary' onClick={()=>{
                            

                       
                            const id = toast.loading("Criando grupo")
 
                          fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/create/group-image-2/`, {
                           method: 'POST',
                           credentials: 'include',
                           headers: {
                             'Content-Type': 'application/json',
                             'Authorization': 'Bearer '+Cookies.get("auth_token"),
                           },
                           body:JSON.stringify({d:new Date()})
                         }).then(response => {
                          
                          response.json().then(data=>{

                            toast.update(id, { render:"Grupo criado com sucesso!", type: "success", isLoading: false,autoClose: 3000});
                       
                       
                            this.setState(prevState => ({
                              group_2: [
                                ...prevState.group_2,
                                {code:data.token,itens:{} }  // Adiciona um novo item com imgs como array vazio
                              ]
                            })); 
                            
                          })
                          
                         
                         })
 
 
 
 
                          



                        
                        }}>
                          <FontAwesomeIcon icon={faPlus} color='#fff'/> Adicionar grupo
                        </button>

  
  
  </div>
}
</div>

</div>
</div>




<div className="col-12 mt-3" style={{display:'block'}}>
                    <div className="card">
      
                           
                           <div class="card-header d-flex justify-content-between">
                         
                       
                      


                            </div>
                      

                            <div class="card-body">

            
                            <RiskAppreciationTable 
                            code="hrn-7"
                            data={this.state.FormHrn}
                            resp={(value)=>{
                              this.setState(prevState => ({
                                AtMlhs: [...prevState.AtMlhs,value],
                              }));
                            }}
                            title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>
 <br/>
                  
 <Apc code="6r5t46f5dg65" data={this.state.apc}/>
-aqui

                                                
                           
                            </div>



                         </div>
 
              
</div>










<div className="col-12 mt-3" style={{display:'block'}}>
   <div className="card">
      <div class="card-header d-flex justify-content-between">
              </div>
      <div class="card-body">

<div style={{display:'table',margin:'auto'}}>

</div>



      <RiskAppreciationTable
  code="hrn-8"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>
 <br/>
 <div style={{display:'table',margin:'auto'}}>
  <strong>12.5 Dispositivo de parada de emergência – Geral</strong>
 </div>
 
<br/>
 <Adcs code="fgh654fg65jh4" data={this.state.apc} />
<div>
<Grouplist data={this.state.gplist} code="12.5"/>
</div>
<RiskAppreciationTable
  code="hrn-9"
  data={this.state.FormHrn}
  resp={(value)=>{
    this.setState(prevState => ({
      AtMlhs: [...prevState.AtMlhs,value],
    }));
  }}
  title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>

<br/>
<Apc code="87fgh564654f" data={this.state.apc}/>
     
     

<RiskAppreciationTable
  code="hrn-10"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>
   <br/>
  

      </div>
   </div>
</div>


<div className="col-12 mt-3" style={{display:'block'}}>
   <div className="card">
      <div class="card-header d-flex justify-content-between">
           </div>
      <div class="card-body">

      <div style={{
    display:'table',
    margin:'auto'
   }}>
  <strong>12.6 Manutenção, inspeção, preparação, ajuste, reparo e limpeza.</strong> 
   </div>
<div>
  <p>
  12.11.3 A manutenção, inspeção, reparos, limpeza, ajuste e outras intervenções que se fizerem necessárias devem ser executadas por profissionais capacitados, qualificados ou legalmente habilitados, formalmente autorizados pelo empregador, com as máquinas e equipamentos parados e adoção dos seguintes procedimentos:
  </p>
</div>

<div>
  <p>
  a) Isolamento e descarga de todas as fontes de energia das máquinas e equipamentos, de modo visível ou facilmente identificável por meio dos dispositivos de comando;
  </p>
</div>
<div>
  <p>
  b) Bloqueio mecânico e elétrico na posição “desligado” ou “fechado” de todos os dispositivos de corte de fontes de energia, a fim de impedir a energização, e sinalização com cartão ou etiqueta de bloqueio contendo o horário e a data do bloqueio, o motivo da manutenção e o nome do responsável;
  </p>
  <p>
  c) Medidas que garantam que à jusante dos pontos de corte de energia não exista possibilidade de gerar risco de acidentes;
  </p>
  <p>
  d) Medidas adicionais de segurança, quando for realizada manutenção, inspeção e reparos de máquinas ou equipamentos sustentados somente por sistemas hidráulicos e pneumáticos;
  </p>
  e) Sistemas de retenção com trava mecânica, para evitar o movimento de retorno.

  <p>

  </p>
</div>


<Adcs code="fdgjhgjfg" data={this.state.apc} />
    
<div>
<Grouplist data={this.state.gplist} code="12.6"/>
</div>




    <RiskAppreciationTable
  code="hrn-11"
  data={this.state.FormHrn}
  resp={(value)=>{
    this.setState(prevState => ({
      AtMlhs: [...prevState.AtMlhs,value],
    }));
  }}
  title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>

   


      </div>
   </div>
</div>


<div className="col-12 mt-3" style={{display:'block'}}>
   <div className="card">
      <div class="card-header d-flex justify-content-between">
      
      </div>
      <div class="card-body">


      <Apc code="87fgh654df" data={this.state.apc}/>

    

    <RiskAppreciationTable
  code="hrn-12"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>
<br/>
<div>
  <strong>
  12.7 Sinalização, manuais, procedimentos e capacitação
  </strong>
</div>
<br/>

<Adcs code="fdgj635465g" data={this.state.apc} />
<div>
<Grouplist data={this.state.gplist} code="12.7"/>
</div>
<RiskAppreciationTable
  code="hrn-13"
  data={this.state.FormHrn}
  resp={(value)=>{
    this.setState(prevState => ({
      AtMlhs: [...prevState.AtMlhs,value],
    }));
  }}
  title="APRECIAÇÃO DE RISCO ANTES DAS AÇÕES DE MELHORIAS"/>



      </div>
   </div>
</div>

<div className="col-12 mt-3" style={{display:'block'}}>
   <div className="card">
      <div class="card-header d-flex justify-content-between">
         
      </div>
      <div class="card-body">
<div>
<Apc code="87fgh654df" data={this.state.apc}/>
</div>
      
    

    <RiskAppreciationTable
  code="hrn-14"
  data={this.state.FormHrn}
  title="APRECIAÇÃO DE RISCO DEPOIS DAS AÇÕES DE MELHORIAS"/>




      </div>
      <div class="card-footer d-flex justify-content-between">
                            <h3></h3>
                            <div>
                              
<a href={`${apiUrl}/generate/docx/${info.token}`}>                          
<button type="submit" class="btn btn-primary float-right ml-2">  <FontAwesomeIcon color='#fff' icon={faDownload} />  Baixar .docx</button>
</a>

<a href={`${apiUrl}/generate/${info.token}`}>
<button type="submit" class="btn btn-primary float-right ml-2">  <FontAwesomeIcon color='#fff' icon={faDownload} />  Baixar .pdv</button>
</a>

        
</div>


</div>
   </div>
</div>



                </div>
                









            </div>
        </main>





        <StaticDialog
        isOpen={this.state.slcE}
        title="Selecione o executante:"
        onAfterClose={(result) => {
         
        this.setState({slcE:false,itemE:undefined})
        }}
      >
       <div style={{padding:10}}>
       <select style={{width:'100%'}} onChange={(e)=>{
        var itemE  = e.target.value;
        if(itemE == ""){
          this.setState({itemE:undefined})
          return;
        }
this.setState({itemE})
       }}>
         <option value="">Selecione:</option>
          {this.state.data.exs.map((item)=><>
          
          <option value={item.cnpj}>{item.razao_social} - {this.formatCNPJ(item.cnpj)}</option>
          </>)}
        </select>
       </div>
        <hr/>



        <div class="d-flex justify-content-end" style={{margin:10}}>
  <button type="button" class="btn btn-primary" disabled={this.state.itemE == undefined ? true : false} onClick={(e)=>{
    e.target.classList.add('load')
    fetch(`${apiUrl}/solicitante/${window.location.pathname.split("/")[3]}/atualizar-executante/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+Cookies.get("auth_token"),
      },
      body:JSON.stringify({itemE:this.state.itemE})
     }).then(response => {
if(response.status == 200){

  var it = this.state.data.exs.find(item => item.cnpj === this.state.itemE);







  this.setState(prevState => ({
    data: {
      ...prevState.data,
      executante: {
        ...prevState.data.executante,
        razao_social:it.razao_social,
        cnpj:it.cnpj
      }
    }
  }));

  this.setState(prevState => ({
    data: {
      ...prevState.data,
      info: {
        ...prevState.data.info,
        executante:it.cnpj
      }
    }
  }));

  e.target.classList.remove('load')
  toast.success('Sucesso!')
  this.setState({slcE:false,itemE:undefined})
}
     }).catch(()=>{
      e.target.classList.remove('load')
      toast.error('Falha na comunicação')
     })
  }}>
    <FontAwesomeIcon icon={faSave}/> Salvar
  </button>
</div>

      </StaticDialog>




        <StaticDialog
        isOpen={this.state.upload5}
        title="Adicionar imagem"
        onAfterClose={(result) => {
         
        this.setState({ImgPrev:undefined,upload5:false,upl7:[]})
        }}
      >
         <div style={{margin:15}}>
<div class="area-8">
{this.state.ImgPrev != undefined ? <>

  <div class="preview"  >
  <div class="edit">
  <input type="file" onChange={(e)=>this.AddImg(e)}/>
  <FontAwesomeIcon icon={faPen} color='#fff'/>
  </div>

  <img  src={`${this.state.ImgPrev}`} />
  </div>

</> : <>
  <div class="add">
                        <input class="form-control" type="file"  onChange={(e)=>this.AddImg(e)}/>
                        <FontAwesomeIcon icon={faPlus} color='#282828'/>
                        </div>
</>}
                    

                        
</div>

         </div>
<hr/>


<div class="d-flex justify-content-end" style={{margin:10}}>
  <button type="button" class="btn btn-primary" disabled={this.state.upl7.length == 0 ? true : false} onClick={()=>{
   var file = this.state.upl7;


if(file.length == 0){
  return;
}
const id = toast.loading("Enviando")

   const formData = new FormData();
     if (file) {
       Array.from(file).forEach((file) => {
         formData.append('images', file);
       });
 
 
       fetch(`${apiUrl}/solicitante/imagens/`+window.location.pathname.split("/")[3], {
         method: 'POST',
         body: formData
        }).then(response => {
         if(response.status == 200){
           response.json().then(data=>{
             
           
            toast.update(id, { render:"Salvo!", type: "success", isLoading: false,autoClose: 3000});
                   
 
             this.setState((prevState) => {
               
               const novaLista = [...prevState.images,data[0]];
             
      
               return {
               images:novaLista
               };
             });
 this.setState({upload5:false,ImgPrev:undefined,upl7:[]})
 
 
 
           })
         }
        })

      }
  }}>
    <FontAwesomeIcon icon={faUpload}/> 
     Upload</button>
</div>



      </StaticDialog>






        <StaticDialog
        isOpen={this.state.ModalOpen}
        title="Adicionar imagem"
        onAfterClose={(result) => {
         
        this.setState({ModalOpen:false,txt7:'',ImgPrev:undefined})
        }}
      >

                       

          <div style={{margin:15}}>
              
                       
                       
                        <input type="text" class="form-control form-control-sm" 
                
                      onChange={(e)=>{
                          
                          this.setState({txt7:e.target.value})
                        }}
                        value={this.state.txt7} placeholder='Titulo:' />
                        <br/>


                        <div class="area-8">
{this.state.ImgPrev != undefined ? <>

  <div class="preview"  >
  <div class="edit">
  <input type="file" onChange={(e)=>this.AddImg(e)}/>
  <FontAwesomeIcon icon={faPen} color='#fff'/>
  </div>

  <img  src={`${this.state.ImgPrev}`} />
  </div>

</> : <>
  <div class="add">
                        <input class="form-control" type="file"  onChange={(e)=>this.AddImg(e)}/>
                        <FontAwesomeIcon icon={faPlus} color='#282828'/>
                        </div>
</>}
                    

                        
</div> 





<br/>
<div class="d-flex justify-content-end">
  <button type="button" class="btn btn-primary" onClick={()=>{


   if(this.state.ImgPrev == undefined){
    toast.warn("Selecione a imagem!")
    return;
   }


    const formData = new FormData();


    formData.append('imagem', this.state.upl7[0]);
    formData.append('titulo',this.state.txt7);
    fetch(`${apiUrl}/solicitante/add/grupoi/${window.location.pathname.split("/")[3]}/${this.state.itensModal.item.code}`, {
      method: 'POST',
      body: formData
     }).then(response => {
      if(response.status == 200){
        response.json().then(data=>{
          this.setState((prevState) => {
            const newGrupois = [...prevState.grupoi];
            newGrupois[this.state.itensModal.index].list.push({
              id:data.id,
              img:data.img
            });
            return { grupoi: newGrupois };
          });
          this.setState({ModalOpen:false,ImgPrev:undefined,txt7:''})
          toast.success("Imagem enviada com sucesso!")
        })
      }
     })
  }}>
    <FontAwesomeIcon icon={faUpload}/> 
     Upload</button>
</div>
                        </div>

      </StaticDialog>
        
<ToastContainer/>
        </>
  
    );
  }
}

export default PrevCot;